import React from 'react';
import { MapContainer, TileLayer, Marker, Tooltip, useMap } from 'react-leaflet'
import markVet from '../../../../static/mark.svg'
import L from "leaflet"

let mapRef = ""

function MyComponent() {
  const map = useMap()
  mapRef = map
  return null
}

export const clickEvent = function() {
  setInterval(function () {
     mapRef.invalidateSize();
     mapRef.setZoom(12);
  }, 100);
}

const PrzychodniaMap = () => {

  const positionMacka = [51.74908, 19.56428]

  const markerMackaIcon = new L.Icon({
     iconUrl: markVet,
     iconSize: [180, 180],
     iconAnchor: [95, 105],
     tooltipAnchor: [10, 5]
   });

   if (typeof window !== 'undefined') {
    return (
      <MapContainer center={positionMacka} zoom={13} scrollWheelZoom={false} style={{ height: "300px" }}>
        <MyComponent />
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={positionMacka} icon={(!!markerMackaIcon) ? markerMackaIcon : null}>
          <Tooltip permanent>
            <span className="font-weight-bold">Amicus - gabinet weterynaryjny</span>
            <br/>
            <span>Maćka z Bogdańca 1 lok. A</span>
          </Tooltip>
        </Marker>
      </MapContainer>
    )
  }
  return null
}

export default PrzychodniaMap;
