import React from 'react'
import Layout from '../components/containers/layout'
import Seo from "../components/seo/seo"
import Navbar from '../components/nav/navbar'
import Footer from '../components/nav/footer'
import ClinicChooser from '../components/containers/kontakt/clinicChooser.js'
import Hr from '../components/hr/hr.js'

const Kontakt = () => (
  <Layout>
    <Seo title="Kontakt Amicus"
         description="Godziny otwarcia Pon. - Pt: 8-20 Sobota: 10-14"
     />
    <Navbar />
    <Hr nazwa={ "Kontakt" }/>
    <ClinicChooser />
    <Footer />
  </Layout>
)
export default Kontakt;
