import React from 'react'
import { Tab, Nav, Row, Col, Container } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaw } from "@fortawesome/free-solid-svg-icons"
import './contact.css'
import CentrumMap from './loadableCentrumMap.js'
import PrzychodniaMap from './loadablePrzychodniaMap.js'
import { clickEvent } from './przychodniaMap.js'

class ClinicChooser extends React.Component {

  onButtonClick(event) {
    clickEvent();
  }

  render() {
    return (
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">

        <Nav variant="pills" className="pils_link">
          <Nav.Item className="pils_link_item">
            <Nav.Link eventKey="first" className="list-group-item list-group-item-success list-group-item-action">Centrum weterynaryjne</Nav.Link>
          </Nav.Item>
          <Nav.Item className="pils_link_item" onClick={() => this.onButtonClick()}>
            <Nav.Link eventKey="second" className="list-group-item list-group-item-success list-group-item-action">Gabinet weterynaryjny</Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content className="m-3">
          <Tab.Pane eventKey="first" className="tab_panel">
            <Row className="row_container">
              <Col md={{ span: 5}} className="left_container">
                <h2 className="clinic_name">
                  <FontAwesomeIcon icon={faPaw} className="awesome_icon"/>
                   "Amicus"
                </h2>
                <h3 className="clinic_name">Centrum Weterynaryjne</h3>
                <Container className="working_hours">
                  <h4>Godziny otwarcia</h4>
                  <p>Pon. - Pt: 8-20<br/>
                     Sobota: 10-14<br/>
                     Niedziela: nieczynne<br/>
                  </p>
                </Container>
              </Col>
              <Col md={{ span: 7}} className="right_container">
                <div>
                  <p>ul. Tatrzańska 63 paw. 40</p>
                  <p>93-219, Łódź</p>
                  <p>tel. kom. 730 500 250</p>
                </div>
                <CentrumMap className="map_container"/>
              </Col>
            </Row>
          </Tab.Pane>
          <Tab.Pane eventKey="second" className="tab_pane">
          <Row className="row_container">
              <Col md={{ span: 5}} className="left_container">
                <h2 className="clinic_name">
                  <FontAwesomeIcon icon={faPaw} className="awesome_icon"/>
                   "Amicus"
                </h2>
                <h3 className="clinic_name">Gabinet Weterynaryjny</h3>
                <Container className="working_hours">
                  <h4>Godziny otwarcia</h4>
                  <p>Pon. - Pt: 13-19<br/>
                     Sobota: 10-13<br/>
                     Niedziela: nieczynne<br/>
                  </p>
                </Container>
              </Col>
              <Col md={{ span: 7}} className="right_container">
                <div>
                  <p>ul. Maćka z Bogdańca 1 lok. A</p>
                  <p>93-434, Łódź</p>
                  <p>tel. kom. 727 522 422</p>
                </div>
                <PrzychodniaMap className="map_container"/>
              </Col>
            </Row>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    );
  }
}

export default ClinicChooser;
